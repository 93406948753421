import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { HttpResponse } from '@wix/fe-essentials/dist/types/exports/http-client';
import { getInstance } from './instance';
import { ErrorCodesMapConditionalEnforcement } from '@wix/error-handler/dist/types/types/ErrorCodesMap';
import type { RequestOptionsFactory } from '@wix/http-client';

type ExtractRequestOptionsData<T> = T extends RequestOptionsFactory<infer R>
  ? R
  : never;

export async function request<Options extends RequestOptionsFactory<any>>({
  flowAPI,
  options,
  additionalHeaders,
  errorOptions,
}: {
  flowAPI: ControllerFlowAPI;
  options: Options;
  additionalHeaders?: Record<'Authorization', string>;
  errorOptions: {
    errorCodesMap: Omit<
      ErrorCodesMapConditionalEnforcement<
        HttpResponse<ExtractRequestOptionsData<Options>>
      >,
      'validationError'
    >;
  };
}): Promise<HttpResponse<ExtractRequestOptionsData<Options>>> {
  const instanceFromUrl = getInstance(flowAPI);
  const { withErrorHandler } = flowAPI.errorHandler;

  return withErrorHandler(
    () =>
      flowAPI.httpClient.request(function (context) {
        const resolvedOptions =
          typeof options === 'function' ? options(context) : options;

        return {
          ...resolvedOptions,
          headers: {
            ...resolvedOptions.headers,
            ...additionalHeaders,
            ...getMLHeaders(flowAPI),
            Authorization: instanceFromUrl,
          },
        };
      } as Options),
    errorOptions as any,
  );
}

export const getMLHeaders = (flowAPI?: Partial<ControllerFlowAPI>) => {
  const headers = {};

  // We need to pass ML header to our BE if its enabled.
  if (flowAPI?.environment?.multilingual.isEnabled) {
    const currMLLanguage = flowAPI.environment.multilingual.siteLanguages.find(
      (lang) =>
        lang?.languageCode === flowAPI.environment.multilingual.currentLanguage,
    );

    if (currMLLanguage) {
      headers[
        'x-wix-linguist'
      ] = `${currMLLanguage?.languageCode}|${currMLLanguage?.locale}|${currMLLanguage?.isPrimaryLanguage}|${flowAPI.environment.appDefinitionId}`;
    }
  }

  return headers;
};
